:root sp-theme[color="light"] {
  --btn-google-background: #fff;
  --btn-google-border: #eaeaea;
  --btn-google-border-hover: #d3d3d3;
  --btn-google-text: #464646;
  --btn-google-text-hover: #000;
  --btn-facebook-background: #1877f2;
  --btn-facebook-border: #1877f2;
  --btn-facebook-border-hover: #166fe5;
  --btn-facebook-text: #fff;
  --btn-facebook-text-hover: #fff;
  --btn-apple-background: #000;
  --btn-apple-border: #000;
  --btn-apple-border-hover: #636366;
  --btn-apple-text: #fff;
  --btn-apple-text-hover: #fff;
  --btn-passkey-background: #fff;
  --btn-passkey-border: #eaeaea;
  --btn-passkey-border-hover: #d3d3d3;
  --btn-passkey-text: #464646;
  --btn-passkey-text-hover: #000;
  --skeleton-background: #e6e6e6;
}

:root sp-theme[color="dark"] {
  --btn-google-background: #fff;
  --btn-google-border: #eaeaea;
  --btn-google-border-hover: #d3d3d3;
  --btn-google-text: #464646;
  --btn-google-text-hover: #000;
  --btn-facebook-background: #fff;
  --btn-facebook-border: #eaeaea;
  --btn-facebook-border-hover: #d3d3d3;
  --btn-facebook-text: #464646;
  --btn-facebook-text-hover: #000;
  --btn-apple-background: #fff;
  --btn-apple-border: #eaeaea;
  --btn-apple-border-hover: #d3d3d3;
  --btn-apple-text: #464646;
  --btn-apple-text-hover: #000;
  --btn-passkey-background: #fff;
  --btn-passkey-border: #eaeaea;
  --btn-passkey-border-hover: #d3d3d3;
  --btn-passkey-text: #464646;
  --btn-passkey-text-hover: #000;
  --skeleton-background: #e6e6e6;
}

app-wrapper {
  --spacing-50: var(--spectrum-global-dimension-static-size-25);
  --spacing-75: var(--spectrum-global-dimension-static-size-50);
  --spacing-100: var(--spectrum-global-dimension-static-size-100);
  --spacing-200: var(--spectrum-global-dimension-static-size-150);
  --spacing-300: var(--spectrum-global-dimension-static-size-200);
  --spacing-400: var(--spectrum-global-dimension-static-size-300);
  --spacing-500: var(--spectrum-global-dimension-static-size-400);
  --spacing-600: var(--spectrum-global-dimension-static-size-500);
  --spacing-700: var(--spectrum-global-dimension-static-size-600);
  --spacing-800: var(--spectrum-global-dimension-static-size-800);
  --spacing-900: var(--spectrum-global-dimension-static-size-1000);
  --spacing-1000: var(--spectrum-global-dimension-static-size-1200);
}

body {
  margin: 0;
}

html, body, sp-theme, app-wrapper, #app {
  color: var(--spectrum-gray-900);
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#app.sentry-loading {
  display: none;
}

.ume-body {
  background-color: var(--spectrum-gray-75);
  overflow: hidden;
}

.ume-body.ume-body--transparent {
  background-color: rgba(0, 0, 0, 0);
}

.unified-mobile {
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  align-items: center;
  display: grid;
}

.sentry-container {
  position: relative;
  overflow: auto;
}

#spinner {
  z-index: 20;
  background-color: rgba(0, 0, 0, 0);
  justify-content: center;
  align-items: center;
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.sentry-loading #spinner {
  display: flex;
}

/*# sourceMappingURL=index.88145a01.css.map */
