:root {
  // Brand Identity Colors

  // ======================== LIGHT THEME ========================
  sp-theme[color='light'] {
    // ------ Google ------
    --btn-google-background: #ffffff;
    --btn-google-border: #eaeaea;
    --btn-google-border-hover: #d3d3d3;
    --btn-google-text: #464646;
    --btn-google-text-hover: #000000;

    // ------ Facebook ------
    --btn-facebook-background: #1877f2;
    --btn-facebook-border: #1877f2;
    --btn-facebook-border-hover: #166fe5;
    --btn-facebook-text: #ffffff;
    --btn-facebook-text-hover: #ffffff;

    // ------ Apple ------
    --btn-apple-background: #000000;
    --btn-apple-border: #000000;
    --btn-apple-border-hover: #636366;
    --btn-apple-text: #ffffff;
    --btn-apple-text-hover: #ffffff;

    // ------ Passkey ------
    --btn-passkey-background: #ffffff;
    --btn-passkey-border: #eaeaea;
    --btn-passkey-border-hover: #d3d3d3;
    --btn-passkey-text: #464646;
    --btn-passkey-text-hover: #000000;

    // ------ Skeleton component ------
    --skeleton-background: #e6e6e6;
  }

  // ======================== DARK THEME ========================
  sp-theme[color='dark'] {
    // ------ Google ------
    --btn-google-background: #ffffff;
    --btn-google-border: #eaeaea;
    --btn-google-border-hover: #d3d3d3;
    --btn-google-text: #464646;
    --btn-google-text-hover: #000000;

    // ------ Facebook ------
    --btn-facebook-background: #ffffff;
    --btn-facebook-border: #eaeaea;
    --btn-facebook-border-hover: #d3d3d3;
    --btn-facebook-text: #464646;
    --btn-facebook-text-hover: #000000;

    // ------ Apple ------
    --btn-apple-background: #ffffff;
    --btn-apple-border: #eaeaea;
    --btn-apple-border-hover: #d3d3d3;
    --btn-apple-text: #464646;
    --btn-apple-text-hover: #000000;

    // ------ Passkey ------
    --btn-passkey-background: #ffffff;
    --btn-passkey-border: #eaeaea;
    --btn-passkey-border-hover: #d3d3d3;
    --btn-passkey-text: #464646;
    --btn-passkey-text-hover: #000000;

    // ------ Skeleton component ------
    --skeleton-background: #e6e6e6;
  }
}
