app-wrapper {
  --spacing-50: var(--spectrum-global-dimension-static-size-25);
  --spacing-75: var(--spectrum-global-dimension-static-size-50);
  --spacing-100: var(--spectrum-global-dimension-static-size-100);
  --spacing-200: var(--spectrum-global-dimension-static-size-150);
  --spacing-300: var(--spectrum-global-dimension-static-size-200);
  --spacing-400: var(--spectrum-global-dimension-static-size-300);
  --spacing-500: var(--spectrum-global-dimension-static-size-400);
  --spacing-600: var(--spectrum-global-dimension-static-size-500);
  --spacing-700: var(--spectrum-global-dimension-static-size-600);
  --spacing-800: var(--spectrum-global-dimension-static-size-800);
  --spacing-900: var(--spectrum-global-dimension-static-size-1000);
  --spacing-1000: var(--spectrum-global-dimension-static-size-1200);
}
