@import 'styles/colors', 'styles/spacing';
@import '../../../node_modules/@spectrum-web-components/styles/theme-dark.css',
  '../../../node_modules/@spectrum-web-components/styles/theme-light.css';

body {
  margin: 0;
}

html,
body,
sp-theme,
app-wrapper,
#app {
  width: 100%;
  height: 100%;
  overflow: hidden;
  color: var(--spectrum-gray-900);
}

#app.sentry-loading {
  display: none;
}

.ume-body {
  background-color: var(--spectrum-gray-75);
  overflow: hidden;
}

.ume-body.ume-body--transparent {
  background-color: transparent;
}

.unified-mobile {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  align-items: center;
}

.sentry-container {
  position: relative;
  overflow: auto;
}

#spinner {
  display: none;
  position: absolute;
  inset: 0;
  align-items: center;
  justify-content: center;
  z-index: 20;
  background-color: transparent;
}

.sentry-loading #spinner {
  display: flex;
}
